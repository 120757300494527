import { Box, Heading, HStack } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { responsive } from '../../components/ThemeProvider/theme'

const Gift = props => (
  <Box width={`2.5em`} {...props}>
    <StaticImage src="1.png" />
  </Box>
)

const arrSize = '0.33em'
const rotate = 8

const arrow = {
  content: '""',
  width: arrSize,
  height: arrSize,
  borderStyle: 'solid',
  borderWidth: arrSize,
  display: 'block',
  position: 'absolute',
}

const GiftTitle = ({ children, ...props }) => {
  return (
    <HStack
      py="0.5em"
      width="full"
      spacing="-3"
      {...props}
    >
      <Gift transform={`rotate(-${rotate}deg)`} />
      <Heading
        display={`block`}
        position={`relative`}
        flex="1"
        fontSize={responsive('md', 'xl')}
        py="1.5em"
        px="1em"
        borderTop="2px solid #4c4245"
        borderBottom="2px solid #4c4245"
        borderRadius={`0.75em`}
        _before={{
          ...arrow,
          left: `-${arrSize}`,
          bottom: `-${arrSize}`,
          borderColor: 'transparent #4c4245 transparent transparent',
          transform: 'rotate(45deg) translate(-70%, -55%)'
        }}
        _after={{
          ...arrow,
          right: `-${arrSize}`,
          top: `-${arrSize}`,
          borderColor: 'transparent transparent transparent #4c4245',
          transform: 'rotate(45deg) translate(70%, 40%)'
        }}
      >
        {children}
      </Heading>
      <Gift transform={`rotate(${rotate}deg)`} />
    </HStack>
  )
}

export default GiftTitle
