import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Page from '../../containers/PickOneGame/Result'
import Seo from '../../containers/Seo'

const Result = (props) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  return (
    <>
      <Seo.Giloo />
      <Page {...props} data={data} />
    </>
  )
}

export default Result
