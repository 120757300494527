import React from 'react'
import {
  Text,
  Image,
  Stack,
  HStack,
} from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '../components/Box';
import Link from '../components/Link';
import Container from '../components/Container';

// import Quizes from './Quizes'
import { responsive } from '../components/ThemeProvider/theme';
import partners from './partners'
import GiftTitle from './GiftTitle';
// import ReButton from '../components/Button';

// const Title = props => (
//   <Box bg="black" color="white" mx={responsive('-1em', '-2em')} py="0.75em" px="1.5em">
//     <Heading as="h3" size={`md`} pb="0.125em" {...props} />
//   </Box>
// )

// const posts = {
//   justfont:'https://url.relab.cc/relabpost-justfont',
//   giloo:'https://url.relab.cc/relabpost-giloo',
//   'bonne-chance':'https://url.relab.cc/relabpost-bc',
//   plainlaw:'https://url.relab.cc/relabpost-plainlawme',
//   xiahai:'https://url.relab.cc/relabpost-tpecitygod',
// }

const Footer = ({ partner , ...props }) => {
  const thePartner = partners.find((p) => p.id === partner)
  return (
    <Box {...props}>
      <Container maxWidth={480} py="2em" bg="white" borderRadius="2em">
        <Stack textAlign="center" spacing="6" alignItems={`center`}>
          <GiftTitle>製作團隊</GiftTitle>
          <HStack spacing={2} justifyContent={`center`} fontSize={responsive('0.75em', '1em')}>
            <Box>
              {thePartner?.logo ?? <Image src={`https://via.placeholder.com/300x100/FFFFFF/000000?text=LOGO`} />}
              <Text>『好好玩{thePartner?.field}設計』</Text>
            </Box>
            <Text>×</Text>
            <Box>
              <StaticImage src="./logos/logo.svg" alt="Re-lab" />
              <Text>『好好玩測驗模組工具』</Text>
            </Box>
          </HStack>
          <Box py="2" />
        </Stack>
      </Container>
      <Box py="4">
        <Text fontSize={`sm`} textAlign={`center`}>
          Copyright © {thePartner.label} x <Link href="https://relab.cc">Re-lab</Link>
        </Text>
      </Box>
    </Box>
  )
}

export default Footer
