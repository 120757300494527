import { Box, Container, HStack, Stack, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'

import Button from '../../components/Button'
// import Link from '../../components/Link'
import { responsive } from '../../components/ThemeProvider/theme'
import Footer from '../Footer'

const Action = (props) => (
  <Button.Giloo
    rounded="full"
    px="1em"
    height="2em"
    fontSize={responsive('1.5em', '1.75em')}
    colorScheme="red"
    {...props}
  />
)

const Result = ({ location: { state = {}, pathname }, data: { site: { siteMetadata } } }) => {
  const { result } = state
  const gameStart = pathname.replace('result/', '')
  // const pageUrl = `${siteMetadata.siteUrl}${gameStart}`
  useEffect(() => {
    if (!result || !result.name) navigate(gameStart)
  }, [])
  if (!result) return null
  return (
    <Box bg="giloo.yellow">
      <Container spacing="1em" py="2em">
        {/* <Heading textAlign={`center`} size={`lg`}>{result.title}</Heading> */}
        <Box maxWidth={`full`}>
          <GatsbyImage image={getImage(result.image)} />
        </Box>
        <Text fontSize={responsive('1em', '1.25em')}>{result.description}</Text>
        <HStack my="2em" width={`full`} justifyContent={`center`} spacing={4}>
          <Action href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://url.relab.cc/quiz_share_giloo')}`}>
            分享測驗
          </Action>
          <Action to={gameStart}>
            再玩一次
          </Action>
        </HStack>
        <Stack spacing={`1em`}>
          <Box textAlign={`center`}>
            <Button fontSize={responsive('1.5em', '1.75em')} colorScheme="red" href={result.action}>兌換免費觀影序號</Button>
          </Box>
          <Text>Giloo 的命名，源自「紀錄」的發音，是台灣唯一以影展及議題為導向的影音平台，搜羅台灣與全世界最重要的電影，致力打造影迷與知識影像的文化社群。</Text>
        </Stack>
      </Container>
      <Footer partner={`giloo`} mt={`2em`} />
    </Box>

  )
}

export default Result
